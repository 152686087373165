exports.components = {
  "component---src-i-18-n-redirect-js": () => import("./../../../src/i18n/redirect.js" /* webpackChunkName: "component---src-i-18-n-redirect-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-success-js": () => import("./../../../src/pages/contactSuccess.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-js": () => import("./../../../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-talks-js": () => import("./../../../src/pages/talks.js" /* webpackChunkName: "component---src-pages-talks-js" */),
  "component---src-templates-entry-detail-js": () => import("./../../../src/templates/entryDetail.js" /* webpackChunkName: "component---src-templates-entry-detail-js" */),
  "component---src-templates-tags-template-js": () => import("./../../../src/templates/tagsTemplate.js" /* webpackChunkName: "component---src-templates-tags-template-js" */)
}

